import { json } from "@remix-run/cloudflare";
import { useLoaderData, useParams } from "@remix-run/react";

import { isSimpleSection } from "~/typings";
import { getDictionary } from "~/utils/dictionaries";
import { localeTokenMap } from "~/utils/localeTokenMap";

import { getSdk } from "../../generated/schema.graphcms";
import { mergeMeta } from "../../metatags/mergeMeta";
import { getGraphCms, getStage } from "../../services/graphcms";

import Divbar from "./components/home/Divbar";
import HomeIntroDesktop from "./components/home/IntroDesktop";
import HomeIntroMobile from "./components/home/IntroMobile";
import LearnMore from "./components/home/LearnMore";
import PlayNowModal from "./components/home/PlayNowModal";
import Sections from "./components/home/Sections";

import type { Locale, Brand, GetPageQuery, GetArticlesQuery } from "../../generated/schema.graphcms";
import type { LoaderFunctionArgs, MetaFunction } from "@remix-run/cloudflare";
import type { SimpleSection } from "~/typings";
import type { Dictionary } from "~/utils/dictionaries";

const slug = "sideswipe-home";

export const meta: MetaFunction<typeof loader> = mergeMeta<typeof loader>(({ data, params }) => {
	const { lang } = params;

	const { page, url: requestUrl } = data ?? {};

	const url = requestUrl ? new URL(requestUrl) : undefined;

	const metaTags = [
		{ title: "Rocket League Sideswipe" },
		{ property: "description", content: page?.description },
		{ property: "url", content: `/${lang}` },

		{ property: "og:title", content: page?.title },
		{ property: "og:description", content: page?.description },
		{ property: "og:url", content: `/${lang}` },
		{
			property: "og:site_name",
			content: "Rocket Leauge Sideswipe Official Website",
		},
		{ property: "og:lang", content: lang },
		{ property: "og:type", content: "website" },

		{ property: "twitter:card", content: "summary_large_image" },
		{
			property: "twitter:title",
			content: "Rocket League ® - Official Site",
		},
	];

	if (url) {
		metaTags.push({
			property: "og:image",
			content: `${url.origin}/images/RLS_KeyArt_Primary_Horiz_RGB_4K.jpg`,
		});
	} else {
		console.error("No request URL found for meta tags");
	}

	return metaTags;
});

async function getPageData(env: Env, preview: boolean, mappedLocaleToken: string): Promise<GetPageQuery["page"]> {
	const { GetPage } = getSdk(getGraphCms(env, preview));
	const pageData = await GetPage({
		locale: [mappedLocaleToken, "en"] as Locale[],
		slug,
		stage: getStage(false),
	});
	return pageData.page;
}

async function getArticlesData(env: Env, preview: boolean, mappedLocaleToken: string): Promise<GetArticlesQuery> {
	const { GetArticles } = getSdk(getGraphCms(env, preview));
	const articleData = await GetArticles({
		locale: [mappedLocaleToken, "en"] as Locale[],
		website_contains_all: ["Sideswipe", "Sideswipe"] as Brand[],
		limit: 2,
		offset: 0,
	});
	return articleData;
}

export default function Home(): React.ReactNode {
	const { page, articleData, dict } = useLoaderData<typeof loader>();

	const { lang: locale = "en" } = useParams();
	const lowerSections = page?.sections.slice(1) as SimpleSection[];

	const simpleSection = page?.sections[0] as SimpleSection | undefined | null;

	return (
		<>
			<div className="is-hidden-desktop">
				<HomeIntroMobile locale={locale} copy={dict} tileData={articleData} />
			</div>
			<div className="is-hidden-touch">
				<HomeIntroDesktop locale={locale} copy={dict} tileData={articleData} />
			</div>
			<Divbar />
			{/* @TODO: should we be preventing the `null` eventuality here, and determine the section in the loader instead? */}
			{!!simpleSection && isSimpleSection(simpleSection) ? <LearnMore data={simpleSection} /> : null}
			<Divbar />
			{/* @TODO: same here as above */}
			<Sections data={lowerSections} />
			<Divbar />
			<PlayNowModal locale={locale} copy={dict} />
		</>
	);
}

export async function loader({ request, params, context }: LoaderFunctionArgs) {
	const { lang: locale = "en" } = params;
	const { env } = context.cloudflare;
	const url = new URL(request.url);
	const preview = url.searchParams.get("preview") === "true";

	const dict = await getDictionary(locale);
	const mappedLocaleToken = localeTokenMap(locale);

	const page = await getPageData(
		env,

		preview,
		mappedLocaleToken,
	);
	const articleData = await getArticlesData(env, preview, mappedLocaleToken);

	return json<{
		page: GetPageQuery["page"];
		articleData: GetArticlesQuery;
		dict: Dictionary;
		url: string;
	}>({ page, articleData, dict, url: request.url });
}
