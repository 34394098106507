import { Link } from "@remix-run/react";

import Tile from "../news/Tile";

import styles from "./styles/HomepageIntro.module.scss";

import type { GetArticlesQuery } from "~/generated/schema.graphcms";
import type { Dictionary } from "~/utils/dictionaries";

export default function HomeIntroMobile({
	locale,
	copy,
	tileData,
}: {
	locale: string;
	copy: Dictionary;
	tileData: GetArticlesQuery;
}): React.ReactNode {
	const tiles = tileData.articles;
	return (
		<section className={`mobile hero ${styles.keyart}`}>
			<div className={`${styles.keyartInner} pb-6`}>
				<div className="hero-body py-0">
					<div className={`has-text-centered mt-0 ${styles.playTrailerWrap}`}>
						<Link to="https://www.youtube.com/watch?v=1knKmT4N-ZY">
							<button className="button is-medium mt-3 mr-1">
								<span className="is-uppercase is-size-5-mobile">{copy.buttons.playtrailer}</span>
							</button>
						</Link>
						<Link to={`/${locale}/download`}>
							<button className="button is-medium mt-3 ml-1">
								<span className="is-uppercase is-size-5-mobile">{copy.buttons.download}</span>
							</button>
						</Link>
					</div>

					<div className={`has-text-centered ${styles.downArrowWrap}`}>
						<img src="/images/home/scroll-indicator-2x.png" alt="Scroll Down" />
					</div>
				</div>

				<div className={`container max-width ${styles.featuredBg}`}>
					<div className="columns is-variable is-multiline is-mobile is-vcentered is-centered m-0">
						{tiles.map((article) => {
							return article.slug && article.title && article.image && article.image.url ? (
								<Tile key={article.slug} title={article.title} slug={article.slug} imageUrl={article.image.url} />
							) : null;
						})}
					</div>
				</div>
			</div>
		</section>
	);
}
