import styles from "./styles/LearnMore.module.scss";

import type { SimpleSection } from "~/typings";

export default function LearnMore({ data }: { data: SimpleSection }): React.ReactNode {
	return (
		<section key={data.id} className={`mobile hero ${styles.sectionWrapper}`}>
			<div className={`container is-fullhd`}>
				<div className="columns is-mobile is-multiline is-centered has-text-centered">
					<div className={`column is-three-quarters-mobile is-four-fifths-tablet is-three-fifths-desktop`}>
						<h2 className={`section-title ${styles.sectionTitle}`}>{data.title}</h2>
					</div>
					<div className={`column is-three-quarters-mobile is-three-fifths-tablet is-two-fifths-desktop`}>
						<p className={`section-copy ${styles.sectionCopy}`}>{data.content}</p>
					</div>
				</div>
			</div>
		</section>
	);
}
