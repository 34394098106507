import { Link } from "@remix-run/react";
import { useEffect } from "react";

import Tile from "../news/Tile";

import styles from "./styles/HomepageIntro.module.scss";
import parallaxstyles from "./styles/Parallax.module.scss";

import type { GetArticlesQuery } from "~/generated/schema.graphcms";
import type { Dictionary } from "~/utils/dictionaries";

export default function HomeIntroDesktop({
	locale,
	copy,
	tileData,
}: {
	locale: string;
	copy: Dictionary;
	tileData: GetArticlesQuery;
}): React.ReactNode {
	const tiles = tileData.articles;

	useEffect(() => {
		document.addEventListener("mousemove", parallax);
		function parallax(this: Document, event: { pageX: number; pageY: number }): void {
			this.querySelectorAll("figure.home-effect img").forEach((shift) => {
				const element = shift as HTMLElement;
				const positionData = element.getAttribute("data-value");
				const position = positionData ? parseInt(positionData) : 0;

				const x = (window.innerWidth - event.pageX * position) / 500;
				const y = (window.innerHeight - event.pageY * position) / 500;

				element.style.transform = `translateX(${x}px) translateY(${y}px) translateZ(100px)`;
			});
		}
	}, []);

	return (
		<section className={`desktop hero ${styles.keyart}`}>
			<div className={`${styles.keyartInner}`}>
				<div className={`${parallaxstyles.parallaxWrapper} ${styles.pWrap}`} id="videoContainer">
					<figure className={`home-effect ${parallaxstyles.parallaxBackgrounds}`}>
						<img data-value="0" src="/images/home/08-parallax-bg-BACKDROP.png" alt="Parallax layer 1" />
						<img data-value="1" src="/images/home/07-parallax-left-BLUE-FLAME.png" alt="Parallax layer 2" />
						<img data-value="1" src="/images/home/02-parallax-right-CAR-FLAME.png" alt="Parallax layer 3" />
						<img data-value="2.5" src="/images/home/04-parallax-center-CRITICAL-HIT.png" alt="Parallax layer 4" />
						<img data-value="3" src="/images/home/03-parallax-center-BALL.png" alt="Parallax layer 5" />
						<img data-value="2" src="/images/home/00-parallax-center-logo.png" alt="Parallax layer 6" />
						<img data-value="4" src="/images/home/05-parallax-left-CAR.png" alt="Parallax layer 7" />
						<img data-value="5" src="/images/home/01-parallax-right-CAR.png" alt="Parallax layer 8" />
					</figure>
					<div className="hero-body py-0">
						<div className={`has-text-centered ${styles.playTrailerWrap}`}>
							<Link to="https://www.youtube.com/watch?v=1knKmT4N-ZY">
								<button className="button is-large mr-2">
									<span className="is-uppercase">{copy.buttons.playtrailer}</span>
								</button>
							</Link>
							<Link to={`/${locale}/download`}>
								<button className="button is-large ml-2">
									<span className="is-uppercase">{copy.buttons.download}</span>
								</button>
							</Link>
						</div>

						<div className={`has-text-centered ${styles.downArrowWrap}`}>
							<img src="/images/home/scroll-indicator-2x.png" alt="Scroll Down" />
						</div>

						<div className={`${styles.featuredBg}`}>
							<div className="columns is-variable is-5-mobile is-4-tablet is-3-desktop is-2-widescreen is-2-fullhd is-multiline is-mobile is-vcentered is-centered m-0">
								{tiles.map((article) => {
									return article.title && article.slug && article.image?.url ? (
										<Tile key={article.slug} title={article.title} slug={article.slug} imageUrl={article.image?.url} />
									) : null;
								})}
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}
